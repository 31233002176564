$( document ).ready( function() {

	if (window.innerWidth >= 767) {
        gsap.fromTo([".arrow-right", ".arrow-left"], { 
            rotation: -1,
        },{ 
            rotation: 1,
            duration: .2,
            repeat: -1,
            yoyo: true,
            ease: Power2.easeInOut
        });
        gsap.fromTo([".right-b", ".left-b", ".arrows-answers .center"], { 
            rotation: -1,
        },{ 
            rotation: 1,
            duration: .2,
            repeat: -1,
            yoyo: true,
            ease: Power2.easeInOut
        });
        gsap.fromTo(".arrow-top-left", { 
            rotation: 10,
        },{ 
            rotation: 12,
            duration: .2,
            repeat: -1,
            yoyo: true,
            ease: Power2.easeInOut
        });
        gsap.fromTo(".arrow-top-right", { 
            rotation: 80,
        },{ 
            rotation: 82,
            duration: .2,
            repeat: -1,
            yoyo: true,
            ease: Power2.easeInOut
        });
        gsap.fromTo(".arrow-bottom-right", { 
            rotation: 336,
        },{ 
            rotation: 338,
            duration: .2,
            repeat: -1,
            yoyo: true,
            ease: Power2.easeInOut
        });
        gsap.fromTo(".followApp13 .arrow-right", { 
            rotation: 14,
        },{ 
            rotation: 16,
            duration: .2,
            repeat: -1,
            yoyo: true,
            ease: Power2.easeInOut
        });
        gsap.fromTo(".followApp13 .arrow-left", { 
            rotation: 164,
        },{ 
            rotation: 166,
            duration: .25,
            repeat: -1,
            yoyo: true,
            ease: Power2.easeInOut
        });
    }
	const quizCount = $('.quiz-item').length
	let quizCounter = 1
	let quizId = "#quiz"+quizCounter

	/* - - - - - - - - - - - - - -       init inputmask       - - - - - - - - - - - - - - - */
	// $( '[type="tel"]' ).inputmask( '+38 (999) 999 99 99' );

	/* - - - - - - - - - - - - - -       init slick       - - - - - - - - - - - - - - - */
	// $( '.js-slider' ).slick({
	// 	infinite: false,
	// 	arrows: true,
	// 	dots: false,
	// 	slidesToScroll: 1,
	// 	slidesToShow: 2
	// });

	// setTimeout(() => {
	// 	let a = fullpage_api.getActiveSlide().anchor
	// 	if ( a == "slide2" || a == "slide6" || a == "slide8" || a == "slide13" || a == "slide14" || a == "slide17" ){
	// 		let constraints = {
	// 			video: true
	// 		}
	// 		let video = document.getElementById(a);

	// 		function handleSuccess(stream) {
	// 			window.stream = stream; // only to make stream available to console
	// 			video.srcObject = stream;
	// 		}

	// 		function handleError(error) {
	// 			console.log('getUserMedia error: ', error);
	// 		}

	// 		navigator.mediaDevices.getUserMedia(constraints).
	// 		then(handleSuccess).catch(handleError);
	// 	}
	// }, 1000)

	// $('.fp-custom-arrow').on('click', function(){
	// 	let a = fullpage_api.getActiveSlide().anchor
	// 	if ( a == "slide2" || a == "slide6" || a == "slide8" || a == "slide13" || a == "slide14" || a == "slide17" ){
	// 		let constraints = {
	// 			video: true
	// 		}
	// 		let video = document.getElementById(a);

	// 		function handleSuccess(stream) {
	// 			window.stream = stream; // only to make stream available to console
	// 			video.srcObject = stream;
	// 		}

	// 		function handleError(error) {
	// 			console.log('getUserMedia error: ', error);
	// 		}

	// 		navigator.mediaDevices.getUserMedia(constraints).
	// 		then(handleSuccess).catch(handleError);
	// 	}
	// })

	$('.answer').on('click', function() {
		if (!$(this).parent().hasClass("close")){
			$(this).addClass("active")
			$(this).parent().addClass("close")
		}
		if (window.innerWidth <= 767) {
			setTimeout(() => {
				if (quizCounter <= 2){
					$(quizId).css("display", "none");
					quizCounter += 1
					quizId = "#quiz"+quizCounter
					$(quizId).css("display", "flex");
					gsap.fromTo(quizId, { xPercent: 20, opacity: 0 }, { xPercent: 0, opacity: 1, duration: 1, ease: Power1.easeOut })
				}
			}, 2000)
		}
    })

	// const ios = () => {
	// 	if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
	
	// 	return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor || (window.opera && opera.toString() === `[object Opera]`));
	// };

	// if (ios()) {
		$('#fullpage').css('height', '100dvh')
		$('#fullpage').css('transform', 'translate3d(0px, 0px, 0px)')
	// }

	$("#contactForm").validate({
		rules: {
			name: {
				required: true,
				maxlength: 100,
				minlength: 3
			},
			email: {
				required: true,
				email: true
			},
			company: {
				required: true,
				maxlength: 100,
				minlength: 3
			},
			message: {
				required: true,
				maxlength: 500,
				minlength: 3
			},
			// legal: "required"
		},
		unhighlight: function(element){
			$(element).removeClass('has-error');
		},
		errorPlacement: function (error, element) {
			$(element).addClass('has-error');
		},
		submitHandler: function (form) {
			var $form = $("#contactForm");
			$.ajax({type:"POST",url:"ajax.php",data:"action=send_form&"+$form.serialize(),success:function success(data){
							$form.find("input [type=submit]").attr("disabled","disabled");
							$form[0].reset();
							$form.next(".thank").removeClass("hidden");
						}
					});
		}
	});
});
